export const config = {
    RPC_URL: 'https://rpc-fetchhub.fetch.ai',
    REST_URL: 'https://rest-fetchhub.fetch.ai',
    EXPLORER_URL: 'https://explore-fetchhub.fetch.ai',
    STAKING_URL: 'https://fetch.omniflix.co/stake',
    NETWORK_NAME: 'Fetch',
    NETWORK_TYPE: 'mainnet',
    CHAIN_ID: 'fetchhub-4',
    CHAIN_NAME: 'Fetch',
    COIN_DENOM: 'FET',
    COIN_MINIMAL_DENOM: 'afet',
    COIN_DECIMALS: 18,
    PREFIX: 'fetch',
    COIN_TYPE: 118,
    COSMOSTAION: 'fetch.ai',
    COINGECKO_ID: 'fetch-ai',
    GAS_PRICE_STEP_LOW: 0.01,
    GAS_PRICE_STEP_AVERAGE: 0.025,
    GAS_PRICE_STEP_HIGH: 0.04,
    FEATURES: ['ibc-transfer', 'ibc-go'],
};
